import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Space } from 'antd';
import Context from '../../context/context';
import Counter from '../counter/counter.js';
import './highlights.css';

const Highlights = (props) => {
  const { className } = props;

  const { filteredReviews } = useContext(Context);

  const [numberOfUniqueVisits, setNumberOfUniqueVisits] = useState(1);
  const [trafficIncrease, setTrafficIncrease] = useState(0);

  useEffect(() => {
    if (!filteredReviews.length) {
      return;
    }
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const visits = filteredReviews.reduce((numberOfVisis, dateItem) => {
      const dateItemDate = new Date(dateItem.date)
      if (date.getTime() < dateItemDate.getTime()) {
        return numberOfVisis + 1;
      }
      return numberOfVisis;
    }, 0);
    date.setMonth(date.getMonth() - 1);
    const visitsPrevMonth = filteredReviews.reduce(
      (numberOfVisis, dateItem) => {
        const dateItemDate = new Date(dateItem.date)
        if (date.getTime() < dateItemDate.getTime()) {
          return numberOfVisis + 1;
        }
        return numberOfVisis;
      },
      0
    );
    setNumberOfUniqueVisits(visits);
    setTrafficIncrease(
      Math.floor((visits / (visitsPrevMonth - visits)) * 100) + '%'
    );
  }, [filteredReviews]);

  return (
    !!filteredReviews.length && false &&
    <Card className={className} bordered={false}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Counter
          title="Number of unique visitors"
          value={numberOfUniqueVisits}
          dimension="Unique Visitors"
        />
        <Counter
          className="counter-2"
          title="Total Traffic"
          value={trafficIncrease}
          dimension="compared to last month."
        />
      </Space>
    </Card>
  );
};

Highlights.propTypes = {};

Highlights.defaultProps = {};

export default Highlights;
