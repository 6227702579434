import React, { useContext } from 'react';
import { FaDownload } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Context from '../../context/context';
import * as d3 from 'd3';

const DownloadPricesPageDataButton = () => {
  const { filteredEstablishments } = useContext(Context);

  const computePricesOverTime = () => {
    const priceMap = filteredEstablishments.reduce(
      (priceAcc, establishment) => {
        if (
          establishment.price_set[0] &&
          establishment.price_set[0].price_range_avg
        ) {
          const dateKey = establishment.price_set[0].time_of_scrape_iso;
          if (priceAcc[dateKey]) {
            priceAcc[dateKey].push(establishment.price_set[0].price_range_avg);
          } else {
            priceAcc[dateKey] = [establishment.price_set[0].price_range_avg];
          }
        }
        return priceAcc;
      },
      {}
    );

    return Object.keys(priceMap)
      .map((dateKey) => {
        const prices = priceMap[dateKey];
        const averagePrice =
          prices.reduce((acc, price) => acc + price, 0) / prices.length;
        return {
          date: new Date(dateKey),
          value: averagePrice,
        };
      })
      .sort((a, b) => a.date - b.date);
  };

  const computePriceRanges = () => {
    const ranges = { '0-50': 0, '51-100': 0, '101-150': 0, '151+': 0 };
    filteredEstablishments.forEach((establishment) => {
      const price = establishment.price_set?.[0]?.price_range_avg || 0;
      if (price <= 50) ranges['0-50'] += 1;
      else if (price <= 100) ranges['51-100'] += 1;
      else if (price <= 150) ranges['101-150'] += 1;
      else ranges['151+'] += 1;
    });
    return Object.entries(ranges).map(([range, count]) => ({ range, count }));
  };

  const computeAveragePriceBySubcategory = () => {
    const groupedData = d3.group(filteredEstablishments, (d) => d.subcategory);

    return Array.from(groupedData, ([key, values]) => ({
      key,
      value: d3.mean(
        values.flatMap((d) =>
          d.price_set
            .filter((p) => p.price_range_avg != null)
            .map((p) => p.price_range_avg)
        )
      ),
    })).filter((item) => item.value !== undefined);
  };

  function groupPricesByRankingRangeForSubcategory(filteredEstablishments) {
    const itemsWithScores = filteredEstablishments.filter(
      (item) => item.ranking_score !== null
    );
    itemsWithScores.forEach((item) => {});

    const formatPrice = (price) =>
      price !== null ? `$${price.toFixed(2)}` : null;

    const groupedBySubcategory = d3.group(
      filteredEstablishments,
      (d) => d.subcategory
    );

    const result = Array.from(groupedBySubcategory, ([subcategory, items]) => {
      const rankingRanges = {
        low: [],
        medium: [],
        high: [],
      };

      items.forEach((item) => {
        if (item.ranking_score !== null) {
          if (item.price_set && item.price_set.length > 0) {
            const latestPrice = item.price_set[0];

            if (latestPrice && latestPrice.price_range_avg !== null) {
              if (item.ranking_score < 7.0) {
                rankingRanges.low.push(latestPrice.price_range_avg);
              } else if (item.ranking_score <= 8.0) {
                rankingRanges.medium.push(latestPrice.price_range_avg);
              } else {
                rankingRanges.high.push(latestPrice.price_range_avg);
              }
            }
          }
        }
      });

      const processedRanges = {
        low: rankingRanges.low.length > 0 ? d3.mean(rankingRanges.low) : null,
        medium:
          rankingRanges.medium.length > 0
            ? d3.mean(rankingRanges.medium)
            : null,
        high:
          rankingRanges.high.length > 0 ? d3.mean(rankingRanges.high) : null,
      };

      return {
        subcategory,
        low: formatPrice(processedRanges.low),
        medium: formatPrice(processedRanges.medium),
        high: formatPrice(processedRanges.high),
        itemsWithRankings: items.filter((i) => i.ranking_score !== null).length,
      };
    });

    // Filter out entries where all ranges are null
    const filteredResult = result.filter(
      (entry) =>
        entry.low !== null || entry.medium !== null || entry.high !== null
    );

    return filteredResult;
  }

  function groupPricesByState(filteredEstablishments) {
    const statePrices = {};

    filteredEstablishments.forEach((item) => {
      const state = item.state;
      if (!state) return;
      const prices = item.price_set
        .filter((p) => p.price_range_avg != null) // Filter valid price entries
        .map((p) => p.price_range_avg); // Extract average prices

      if (!statePrices[state]) {
        statePrices[state] = [];
      }

      statePrices[state].push(...prices); // Collect all prices for the state
    });

    // Calculate average price for each state
    const result = Object.entries(statePrices).map(([state, prices]) => ({
      state,
      averagePrice: prices.length
        ? prices.reduce((sum, p) => sum + p, 0) / prices.length
        : null,
    }));

    return result;
  }

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();

    const pricesOverTime = XLSX.utils.json_to_sheet(computePricesOverTime());
    const priceRanges = XLSX.utils.json_to_sheet(computePriceRanges());
    const averagePriceBySubcategory = XLSX.utils.json_to_sheet(
      computeAveragePriceBySubcategory()
    );
    const pricesByRankingRangeForSubcategory = XLSX.utils.json_to_sheet(
      groupPricesByRankingRangeForSubcategory(filteredEstablishments)
    );
    const pricesByState = XLSX.utils.json_to_sheet(
      groupPricesByState(filteredEstablishments)
    );

    XLSX.utils.book_append_sheet(workbook, pricesOverTime, 'Prices Over Time');
    XLSX.utils.book_append_sheet(workbook, priceRanges, 'Price Ranges');
    XLSX.utils.book_append_sheet(
      workbook,
      averagePriceBySubcategory,
      'Average Price by Subcategory'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      pricesByRankingRangeForSubcategory,
      'Ranking Range for Subcategory'
    );
    XLSX.utils.book_append_sheet(workbook, pricesByState, 'Prices by State');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'prices-data.xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleDownloadExcel}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadPricesPageDataButton;
