import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import { formatFilters } from '../../utils/format-filters.js';

import './bar-chart.css';

const MINIMUM_BAR_HEIGHT = 2;
const SMALL_VALUE_THRESHOLD = 50;
const SMALL_VALUE_SCALING_FACTOR = 2;

const BarChart = (props) => {
  const {
    id,
    data,
    xTickTotated,
    height,
    color,
    fileName,
    columns,
    filters,
    title,
  } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');

  const [width, setWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);

  let margin = {
    top: 12,
    right: 0,
    bottom: xTickTotated ? 164 : 46,
    left: 72,
  };

  function initChart() {
    let chartContainer = document.getElementById(`chart-${id}`);
    let initialWidth =
      chartContainer.getBoundingClientRect().width - margin.left - margin.right;

    // Adjust total height to maintain graph size
    let totalHeight = (height || 200) + margin.bottom;
    let calculatedSvgHeight = totalHeight - margin.top - margin.bottom;

    let svg = d3
      .select(`#chart-${id}`)
      .append('svg')
      .attr('width', initialWidth + margin.left + margin.right)
      .attr('height', totalHeight)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    svg
      .append('g')
      .attr('transform', `translate(0, ${calculatedSvgHeight})`)
      .attr('class', 'x-axis');

    svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(0, 0)`);

    setWidth(initialWidth);
    setSvgHeight(calculatedSvgHeight);
  }

  function updateChart() {
    let svg = d3.select(`#chart-${id} svg g`);

    let x = d3
      .scaleBand()
      .range([0, width])
      .domain(
        data.map(function (d) {
          return d.key;
        })
      )
      .padding(0.2);

    let y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d) {
          return d.value;
        }),
      ])
      .range([svgHeight, 0]);

    function mouseover(e, d) {
      svg.selectAll('rect').attr('fill-opacity', 0.6);
      this.setAttribute('fill-opacity', 1);
      let rects = svg.selectAll('rect').nodes(),
        index = rects.indexOf(this);
      svg.selectAll('.x-axis text').attr('fill-opacity', 0.6);
      svg
        .selectAll('.x-axis text')
        .filter((d, i) => i === index)
        .attr('fill-opacity', 1);
      this.setAttribute('fill-opacity', 1);
      setTooltipTitle(d.key);
      setTooltipValue(d.value);
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(e.offsetY + margin.top + 'px');
      setTooltipLeft(e.offsetX + 'px');
    }

    function mouseleave(d) {
      svg.selectAll('rect').attr('fill-opacity', 1);
      svg.selectAll('.x-axis text').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    svg.selectAll('.x-axis').transition().duration(1000).call(d3.axisBottom(x));

    if (xTickTotated) {
      svg
        .selectAll('.x-axis text')
        .attr('transform', 'rotate(-40)')
        .attr('text-anchor', 'end')
        .attr('dx', '-.4em')
        .attr('dy', '.1em');
    }

    svg
      .selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(d3.axisLeft(y).ticks(7));

    svg
      .selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line')
      .remove();

    svg.selectAll('.tick text').attr('class', 'chart_tick_text');

    svg
      .selectAll('.mybar')
      .data(data)
      .join('rect')
      .attr('class', 'mybar')
      .attr('fill', color ? color : '#ffffff')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .transition()
      .duration(1000)
      .attr('rx', () => {
        return x.bandwidth() > 16 ? 8 : x.bandwidth() / 2;
      })
      .attr('x', (d) => {
        return x.bandwidth() > 16
          ? (x.bandwidth() - 16) / 2 + x(d.key)
          : x(d.key);
      })
      .attr('y', (d) => {
        const calculatedHeight = svgHeight - y(d.value);
        if (d.value < SMALL_VALUE_THRESHOLD) {
          const scaledHeight = calculatedHeight * SMALL_VALUE_SCALING_FACTOR;
          return svgHeight - Math.max(scaledHeight, MINIMUM_BAR_HEIGHT);
        }
        return svgHeight - Math.max(calculatedHeight, MINIMUM_BAR_HEIGHT);
      })
      .attr('width', () => {
        return x.bandwidth() > 16 ? 16 : x.bandwidth();
      })
      .attr('height', (d) => {
        const calculatedHeight = svgHeight - y(d.value);
        if (d.value < SMALL_VALUE_THRESHOLD) {
          const scaledHeight = calculatedHeight * SMALL_VALUE_SCALING_FACTOR;
          return Math.max(scaledHeight, MINIMUM_BAR_HEIGHT);
        }
        return Math.max(calculatedHeight, MINIMUM_BAR_HEIGHT);
      });
  }

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data]);

  return (
    <div className="chart-wrapper">
      <div className="chart-container" id={`chart-${id}`}>
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className="download-btns regions-downloads">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="bar-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

BarChart.propTypes = {};

BarChart.defaultProps = {};

export default BarChart;
