import axios from 'axios';
import IndexedDb from './indexedDb'

const path = '/api';

async function getReviews(year) {
  const resp = await axios.get(`${path}/reviews/?year=${year}`)

  return resp.data.map((o) => ({
    ...o,
    date: new Date(o.travel_date),
  }))
}

 async function getEstablishments() {
  const resp = await  axios.get(`${path}/establisments/`)
  return resp.data
}

async function checkDataVersion(malawiTourismDb) {
  const storedDataVersion = await malawiTourismDb.getOtherData('dataversion');
  const dataversion =  await axios.get(`${path}/dataversion/`).then((response) => response.data)

  const currentDataVersionDate = dataversion[0]["date_string"]
  const storedDataVersionDate = storedDataVersion?.dataversion || '2024-12-01'
  if (currentDataVersionDate) {
    const storedDate = new Date(storedDataVersionDate);
    const currentDate = new Date(currentDataVersionDate);

    if (currentDate > storedDate) {
      await malawiTourismDb.clearData('others')
      await malawiTourismDb.clearData('reviews')

      const dataVersionObj = {
        key: 'dataversion',
        dataversion: currentDataVersionDate
      }
      await malawiTourismDb.addOtherData(dataVersionObj);
    }
  }
}

async function getAllData(year) {
  const malawiTourismDb = new IndexedDb();
  await malawiTourismDb.init([{
    name: 'reviews', key: 'year'},
    {name: 'others', key: 'key'}
  ]);

  await checkDataVersion(malawiTourismDb)

  let storedReviews = []
  let storedEstablishments = []
  let apiCalls = []

  const storedReviewsObject = await malawiTourismDb.getReviewsByYear(year);
  if (storedReviewsObject && Object.keys(storedReviewsObject).length) {
    storedReviews = storedReviewsObject.reviews
  } else {
    apiCalls.push("reviews")
  }

  const storedEstablishmentsObject = await malawiTourismDb.getOtherData('establishments');
  if (storedEstablishmentsObject && Object.keys(storedEstablishmentsObject).length) {
    storedEstablishments = storedEstablishmentsObject.establishments
  } else {
    apiCalls.push("establishments")
  }

  try {
    if (year === 'All' && !storedReviews.length) {
      return getAllMissingData(malawiTourismDb)
    }

    const [reviewsResponse, establishmentsResponse] =  await Promise.all([
      apiCalls.includes("reviews") && getReviews(year),
      apiCalls.includes("establishments") && getEstablishments()
    ]);


    const reviewList = {
      year,
      reviews: reviewsResponse
    }

    if (!storedReviewsObject) await malawiTourismDb.addReviewsListByYear(reviewList);

    const establishmentsList = {
      key: 'establishments',
      establishments: establishmentsResponse
    }
    if (!storedEstablishmentsObject) await malawiTourismDb.addOtherData(establishmentsList);

    return {
      reviews: storedReviewsObject ?  storedReviews : reviewsResponse,
      establishments: storedEstablishmentsObject ?  storedEstablishments : establishmentsResponse,
    };
  } catch (error) {
    console.error('Error fetching all data:', error);
    throw error;
  }
}

async function getAllMissingData(malawiTourismDb){
  const years = ['2025', '2024', '2023','2022', '2021'];
  let allData = []
  let missingYears = []

  for (const year of years) {
    const storedReviewsObject = await malawiTourismDb.getReviewsByYear(year);
    if (storedReviewsObject && Object.keys(storedReviewsObject).length) {
      const storedReviews = storedReviewsObject.reviews
      allData.push(storedReviews);
    } else {
      missingYears.push(year);
    }
  }

  if (missingYears.length) {
    const missingReviews = await Promise.all(
      missingYears.map(year => getReviews(year))
    );

    missingYears.forEach(async (year, i) => {
      allData.push(missingReviews[i])

      const reviewList = {
        year,
        reviews: missingReviews[i]
      }

      await malawiTourismDb.addReviewsListByYear(reviewList);
    });
  }

  const allReviews = allData.flat()

  const allReviewList = {
    year: 'All',
    reviews: allReviews
  }

  await malawiTourismDb.addReviewsListByYear(allReviewList);
  let establishments = []
  const storedEstablishmentsObject = await malawiTourismDb.getOtherData('establishments');
  if (storedEstablishmentsObject && Object.keys(storedEstablishmentsObject).length) {
    establishments = storedEstablishmentsObject.establishments
  } else {
    establishments = await getEstablishments()

    const establishmentsList = {
      key: 'establishments',
      establishments
    }

    await malawiTourismDb.addOtherData(establishmentsList);
  }

  return {
    reviews: allReviews,
    establishments: establishments,
  };
}

const service = {
  getAllData,
};
export default service;
