import React, { useEffect, useState, useContext } from 'react';
import { useNavigation, Outlet, useLocation } from 'react-router-dom';
import { Layout, Spin, message, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Context from '../../context/context';
import Navigation from '../../components/navigation/navigation';
import Filters from '../../components/filters/filters';
import DownloadStatisticalDataButton from '../../components/download-as-excel-btn/download-statistical-btn';
import DownloadCategoriesDataButton from '../../components/download-as-excel-btn/download-categories-data-btn';
import DownloadRegionDataButton from '../../components/download-as-excel-btn/download-region-data-btn';
import DownloadAspectsDataButton from '../../components/download-as-excel-btn/download-aspect-data-btn';
import DownloadPricesPageDataButton from '../../components/download-as-excel-btn/download-prices-btn';
import YearSelection from './year-selection';
import service from '../../service';
import LogoutButton from '../../components/logout/logout-btn';
import { scrollToTop } from '../../utils/helpers';
import './root.css';

const { Header, Content, Footer, Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  top: 0,
  bottom: 0,
  width: 150,
  background: 'transparent',
};
const layoutStyle = {
  position: 'relative',
  zIndex: 1,
  background: 'transparent',
  maxWidth: '1600px',
  margin: '0 auto',
};

const pathButtons = {
  '/intro': <DownloadStatisticalDataButton />,
  '/categories': <DownloadCategoriesDataButton />,
  '/regions': <DownloadRegionDataButton />,
  '/aspects': <DownloadAspectsDataButton />,
  '/prices': <DownloadPricesPageDataButton />,
};

function Root() {
  const {
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedDates,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateFilteredEstablishments,
    updateNoPlatformFilteredReviews,
    updateNoPlatformFilteredEstablishments,
    updateActiveSubCategories,
    updateFilteredReviews,
    setEstablishmentTypes,
    selectedYear,
    updateSelectedYear,
    fetchingData,
    updateFetchingData,
    hasError,
    updateHasError,
  } = useContext(Context);

  const [reviews, setReviews] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [currentYear, setCurrentYear] = useState(selectedYear);
  const [collapsed, setCollapsed] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const location = useLocation();

  const navigation = useNavigation();
  const isNavigating = navigation.state === 'loading';

  function applyPlatformFilters(activeReviews) {
    if (selectedPlatform === null) return activeReviews;
    return activeReviews.filter((r) => {
      return selectedPlatform === r.data_platform;
    });
  }

  // filter establishments based on selected platform
  function applyEstablishmentPlatformFilter(activeEstablishments) {
    if (selectedPlatform === null) return activeEstablishments;
    return activeEstablishments.filter(
      (r) => r.data_platform === selectedPlatform
    );
  }

  function applyNoPlatformFilters(activeReviews) {
    let filteredArray = activeReviews;

    if (selectedCategory !== null) {
      filteredArray = activeReviews.filter((r) => {
        return selectedCategory === r.establishment_type;
      });
    }
    if (selectedSubCategory.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        if (r.establishment_subcategory === null) return false;
        const subcategoryTypes = r.establishment_subcategory.split(',');
        return subcategoryTypes.some((sub) => {
          return selectedSubCategory.includes(sub);
        });
      });
    }
    if (selectedRegion.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedRegion.includes(r.district);
      });
    }
    if (selectedDates.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedDates[0] <= r.date && r.date <= selectedDates[1];
      });
    }
    if (selectedSentiment !== null) {
      filteredArray = filteredArray.filter((r) => {
        return r.aspectsentiment_set.some((as) => {
          return selectedSentiment === as.label;
        });
      });
    }
    if (selectedAspect?.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return Array.isArray(r.aspectsentiment_set) && r.aspectsentiment_set.some((as) => {
          return selectedAspect.includes(as.aspect);
        });
      });
    }
    return filteredArray;
  }

  function applyEstablishmentNoPlatformFilters(activeEstablishments) {
    let filteredArray = activeEstablishments;

    if (selectedCategory !== null) {
      filteredArray = activeEstablishments.filter((r) => {
        return selectedCategory === r.type;
      });
    }
    if (selectedSubCategory.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        if (r.subcategory === null) return false;
        const subcategoryTypes = r.subcategory.split(',');
        return subcategoryTypes.some((sub) => {
          return selectedSubCategory.includes(sub);
        });
      });
    }
    if (selectedRegion.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedRegion.includes(r.district);
      });
    }
    if (selectedDates.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedDates[0] <= r.date && r.date <= selectedDates[1];
      });
    }
    if (selectedSentiment !== null) {
      filteredArray = filteredArray.filter((r) => {
        return Array.isArray(r.aspectsentiment_set) && r.aspectsentiment_set.some((as) => {
          return selectedSentiment === as.label;
        });
      });
    }
    if (selectedAspect?.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return Array.isArray(r.aspectsentiment_set) && r.aspectsentiment_set.some((as) => {
          return selectedAspect.includes(as.aspect);
        });
      });
    }
    return filteredArray;
  }

  const renderButtons = () => {
    const currentPath = location.pathname;
    return pathButtons[currentPath] || '';
  };

  function applyEstablishmentTypeFilter(activeEstablishments) {
    let filteredArray = activeEstablishments;
    if (selectedCategory !== null) {
      filteredArray = activeEstablishments.filter((r) => {
        return selectedCategory === r.type;
      });
    }

    if (selectedSubCategory.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        if (r.subcategory === null) return false;
        const subcategoryTypes = r.subcategory.split(',');
        return subcategoryTypes.some((sub) => {
          return selectedSubCategory.includes(sub);
        });
      });
    }

    if (selectedRegion.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedRegion.includes(r.district);
      });
    }
    return filteredArray;
  }

  useEffect(() => {
    const fetchData = async () => {
      updateFetchingData(true);
      updateHasError(false);
      try {
        const {
          reviews: fetchedReviews,
          establishments: fetchedEstablishments,
        } = await service.getAllData(selectedYear);
        setReviews(fetchedReviews);
        setEstablishments(fetchedEstablishments);
        const uniqueTypes = [
          "All Categories",
          ...new Set(fetchedReviews.map((review) => review.establishment_type)),
        ];
        setEstablishmentTypes(uniqueTypes);
        updateFetchingData(false);
      } catch (error) {
        updateFetchingData(false);
        console.error('Error fetching data:', error);
        updateHasError(true);
      }
    };

    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    if (hasError) {
      messageApi.error(
        `There was an error getting reviews for ${currentYear}. Try again later`,
        2.5,
        updateSelectedYear(String(new Date().getFullYear() - 1))
      );
    }
  }, [hasError]);

  useEffect(() => {
    const noPlatformFilters = applyNoPlatformFilters(reviews);
    const noEstablishmentPlatformFilters =
      applyEstablishmentNoPlatformFilters(establishments);

    // Apply the platform filter here before updating state
    const filteredEstablishments = applyEstablishmentPlatformFilter(
      noEstablishmentPlatformFilters
    );

    updateFilteredEstablishments(filteredEstablishments); // Ensure this updates the state with filtered data
    updateNoPlatformFilteredReviews(noPlatformFilters);
    updateFilteredReviews(applyPlatformFilters(noPlatformFilters));
    updateNoPlatformFilteredEstablishments(noEstablishmentPlatformFilters);
  }, [
    reviews,
    establishments,
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedDates,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  ]);

  useEffect(() => {
    scrollToTop();

    let activeSubCategories = reviews.reduce((acc, r) => {
      if (r.establishment_subcategory === null) return acc;
      const subcategoryTypes = r.establishment_subcategory.split(',');
      subcategoryTypes.forEach((sub) => {
        if (!acc.includes(sub)) {
          acc.push(sub);
        }
      });
      return acc;
    }, []);

    activeSubCategories = activeSubCategories.filter((r) => r !== null);
    updateActiveSubCategories(activeSubCategories);
  }, [reviews]);

  useEffect(() => {
    const establishmentTypeFilter =
      applyEstablishmentTypeFilter(establishments);
    updateFilteredEstablishments(establishmentTypeFilter);
  }, [establishments, selectedCategory, selectedSubCategory]);

  const username = localStorage.getItem('username');
  const isAuthenticated = !!localStorage.getItem('accessToken');
  const isHomePage = location.pathname === '/home';
  const isAboutPage = location.pathname === '/';

  return (
    <div className="App">
      {isHomePage || isAboutPage ? (
        <Layout hasSider style={layoutStyle}>
          <Layout
            style={{
              marginLeft: 50,
              marginRight: 50,
              background: 'transparent',
            }}
          >
            <Header
              className="root_header"
              style={{
                padding: 0,
                background: 'transparent',
              }}
            >
              <div className="header"></div>
            </Header>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Spin
          spinning={fetchingData}
          size="large"
          tip="Please wait..."
          fullscreen={fetchingData}
        >
          <Layout hasSider style={layoutStyle}>
            <Sider style={siderStyle}>
              {isAuthenticated && <Navigation />}
            </Sider>
            <Layout className="root-layout">
              <Header className="root_header authenticated">
                {/* <div className="header"></div> */}
                {isAuthenticated && (
                  <Button
                    className="toggle-mobile-nav"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <MenuOutlined />
                  </Button>
                )}

                {isAuthenticated && (
                  <div
                    className={`mobile-navigation ${!collapsed ? 'show' : ''}`}
                  >
                    <Navigation
                      secondaryAction={() =>
                        setTimeout(() => setCollapsed(true), 500)
                      }
                    />
                    <div
                      className="overlay"
                      onClick={() => setCollapsed(true)}
                    />
                  </div>
                )}
                {isAuthenticated && (
                  <YearSelection setCurrentYear={setCurrentYear} />
                )}
                {contextHolder}
                {/* {isAuthenticated && username && location !=='/' ?<span>Welcome, {username}!</span> : ''} */}

                {isAuthenticated && (
                  <div className="header-actions">
                    <LogoutButton />
                    {renderButtons()}
                    <Filters />
                  </div>
                )}
              </Header>
              <Content>
                {/* {isNavigating ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
              </div>
            ) : ( */}
                <Outlet />
                {/* )} */}
              </Content>
              <Footer
                style={{
                  background: 'transparent',
                }}
              ></Footer>
            </Layout>
          </Layout>
        </Spin>
      )}
    </div>
  );
}

export default Root;
