import { select } from 'd3';
import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('accessToken')
  );
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const login = async (username, password) => {
    try {
      const response = await axios.post(
        '/api/login/',
        {
          username,
          password,
        }
      );

      if (response.status === 200) {
        const { access, refresh } = response.data;
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        setIsAuthenticated(true);
        setSuccess(true);
        setError('');

        setTimeout(() => {
          setSuccess(false);
          navigate('/');
        }, 5000);
      }
    } catch (err) {
      setError('Invalid username or password');
      setSuccess(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setSuccess(false);
    navigate('/login');
  };

  useEffect(() => {
    return () => setSuccess(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, success, error }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const Context = createContext({
  filteredReviews: [],
  filteredEstablishments: [],
  noPlatformFilteredReviews: [],
  noPlatformFilteredEstablishments: [],
  activeSubCategories: [],
  updateFilteredReviews: (_d) => {},
  updateFilteredEstablishments: (_d) => {},
  updateActiveSubCategories: (_d) => {},
  selectedPlatform: null,
  selectedCategory: null,
  selectedSubCategory: [],
  selectedDates: null,
  selectedSentiment: null,
  selectedAspect: [],
  selectedRegion: [],
  updateSelectedPlatform: (_d) => {},
  updateSelectedCategory: (_d) => {},
  updateSelectedSubCategory: (_d) => {},
  updateSelectedDates: (_d) => {},
  updateSelectedSentiment: (_d) => {},
  updateSelectedAspect: (_d) => {},
  updateSelectedRegion: (_d) => {},
});

export default Context;
