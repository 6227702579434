import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'antd';
import Context from '../../context/context';
import { ASPECT_TYPES } from '../../constants';
import RadarChart from '../../components/radar/radar';
import Section from '../../components/section/section';
import ChartCard from '../../components/chart-card/chart-card';
import EstablishmentTypeToggle from '../../components/establishment-type-toggle/establishment-type-toggle';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';
import RegionSentimentFilters from '../../components/sentiment-region-filter/sentiment-region-filter';

import './aspects.css';
import { scrollToTop } from '../../utils/helpers';

const Aspect = (props) => {
  const { aspect, value } = props;

  const { selectedAspect, updateSelectedAspect } = useContext(Context);

  const handleClick = () => {
    if (selectedAspect.includes(aspect)) {
      updateSelectedAspect(selectedAspect.filter((a) => a !== aspect));
    } else {
      updateSelectedAspect([...selectedAspect, aspect]);
    }
  };

  return (
    <div
      className={`aspect ${selectedAspect.includes(aspect) ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <div className="aspect-top-container">
        <div className="aspect-icon-container">
          <img
            className="aspect-icon"
            src={`/icons/${aspect.toLowerCase()}.png`}
            alt={aspect}
          />
        </div>
        <div className="aspect-name">{aspect}</div>
      </div>
      <div className="aspect-value">
        <div className="aspect-value-counter">{value}</div>
        Mentions
      </div>
    </div>
  );
};

const Aspects = () => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateSelectedCategory,
    updateSelectedPlatform,
    updateSelectedSubCategory,
    updateSelectedSentiment,
    updateSelectedAspect,
    updateSelectedRegion,
  } = useContext(Context);

  const [aspectsData, setAspectsData] = useState({});
  const [radarData, setRadarData] = useState([]);
  const [localCategory, setLocalCategory] = useState(null);

  // Initialize local state
  useEffect(() => {
    if (selectedCategory !== localCategory) {
      setLocalCategory(selectedCategory);
    }
  }, [selectedCategory]);

  // Clear filters on mount
  useEffect(() => {
    scrollToTop();
    const clearFilters = async () => {
      await updateSelectedCategory(null);
      setLocalCategory(null);
    };
    clearFilters();
  }, []);

  function computeAspectsData() {
    const aspectsDataMap = {};

    ASPECT_TYPES.forEach((aspect) => {
      aspectsDataMap[aspect] = 0;
    });

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        aspectsDataMap[aspect.aspect] += 1;
      });
    });
    return aspectsDataMap;
  }

  function computeRadarData() {
    const radarDataMap = {};

    ASPECT_TYPES.forEach((aspect) => {
      radarDataMap[aspect] = { positive: 0, negative: 0 };
    });

    filteredReviews.forEach((review) => {
      if (!localCategory || review.establishment_type === localCategory) {
        review.aspectsentiment_set.forEach((aspect) => {
          if (aspect.label === 'Positive') {
            radarDataMap[aspect.aspect].positive += 1;
          } else if (aspect.label === 'Negative') {
            radarDataMap[aspect.aspect].negative += 1;
          }
        });
      }
    });

    return Object.keys(radarDataMap).map((aspect) => {
      const positives = radarDataMap[aspect].positive;
      const negatives = radarDataMap[aspect].negative;
      const value =
        positives + negatives === 0
          ? 0
          : Math.round((positives / (positives + negatives)) * 100);

      return {
        aspect: aspect,
        value: value,
        positives: positives,
        negatives: negatives,
      };
    });
  }

  function computeTotalAspects() {
    return Object.values(aspectsData).reduce((acc, value) => acc + value, 0);
  }

  // Handle local filter changes
  const handleFilterChange = async (filterType) => {
    switch (filterType) {
      case 'category':
        await updateSelectedCategory(null);
        setLocalCategory(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setAspectsData(computeAspectsData());
  }, [filteredReviews]);

  useEffect(() => {
    setRadarData(computeRadarData());
  }, [filteredReviews, localCategory]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: localCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Establishment Aspect Insights" />
        </Col>
      </Row>
      <Row
        className="aspects-topbar"
        span={24}
        style={{ justifyContent: 'space-between' }}
      >
        <AppliedFilters onRemoveFilter={handleFilterChange} />
      </Row>
      <div style={{ marginTop: '10px', marginBottom: '20px', display: 'flex' }}>
        <EstablishmentTypeToggle />
        <div><RegionSentimentFilters/></div>
      </div>
      <Col className="aspect-inner-wrapper">
        <div className="overview-block aspects">
          {ASPECT_TYPES.map((aspect) => (
            <Aspect key={aspect} aspect={aspect} value={aspectsData[aspect]} />
          ))}
          <Aspect aspect="Total" value={computeTotalAspects()} />
        </div>

        <Row style={{ flexBasis: '70%' }}>
          <Col span={24}>
            <Section>
              <ChartCard className="chart-card radar-chart">
                <RadarChart
                  data={radarData}
                  columns={{
                    aspect: 'Aspect',
                    value: 'Value',
                    positives: 'Positive sentiments',
                    negatives: 'Negative sentiments',
                  }}
                  fileName="establishment-aspect-performance"
                  filters={appliedFilters}
                  title={'Establishment Aspect Performance Insights'}
                />
              </ChartCard>
            </Section>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Aspects;
