export const scrollToTop = () =>
  document.querySelector('#root')?.scrollIntoView()

export const getBreakPoint = () => {
  const width_px = window.innerWidth;
  if (width_px <= 576) return'xs';

  if (width_px <= 768) return 'sm';

  if (width_px <= 992) return 'md';

  if (width_px <= 1200) return 'lg';

  return 'xl';
}
