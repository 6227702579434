export default class IndexedDb {
  constructor() {
      this.dbName = 'MalawiTourismReviews';
      this.db = null;
      this.isInitialized = false;
  }

  // Initialize the database
  init(storeNames) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, '2025');

      request.onerror = (event) => {
        reject('Indexed Database error: ' + event.target.error);
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        this.isInitialized = true;
        resolve(this.db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        storeNames.forEach(store => {
          db.createObjectStore(store.name, { keyPath: store.key });
        })

      };
    });
  }

  getStore(storeName, mode = 'readonly') {
    if (!this.db) throw new Error('Database not initialized');
    const transaction = this.db.transaction(storeName, mode);
    return transaction.objectStore(storeName);
  }

  addReviewsListByYear(reviewsList) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['reviews'], 'readwrite');
      const store = transaction.objectStore('reviews');
      // Add timestamp to object
      reviewsList.createdAt = new Date().toISOString();
      const request = store.add(reviewsList);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  addOtherData(data) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['others'], 'readwrite');
      const store = transaction.objectStore('others');
      // Add timestamp to object
      data.createdAt = new Date().toISOString();
      const request = store.add(data);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  getReviewsByYear(year) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['reviews']);
      const store = transaction.objectStore('reviews');
      const request = store.get(year);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }


  getOtherData(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['others']);
      const store = transaction.objectStore('others');
      const request = store.get(key);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  deleteData(storeName, key) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.delete(key);

      request.onsuccess = () => resolve(true);
      request.onerror = () => reject(request.error);
    });
  }

  clearData(storeName) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.clear();

      request.onsuccess = () => resolve(true);
      request.onerror = () => reject(request.error);
    });
  }
}
