import React, { useContext } from 'react';
import { Select, Space } from 'antd';
import Context from '../../context/context';
import { SENTIMENT_TYPES, REGION_TYPES } from '../../constants';

const RegionSentimentFilters = () => {
  const {
    selectedSentiment,
    selectedRegion,
    updateSelectedSentiment,
    updateSelectedRegion,
  } = useContext(Context);

  const sentimentTypes = [
    {
      label: 'All',
      value: null,
    },
    ...SENTIMENT_TYPES.map(p => ({
      label: p,
      value: p,
    })),
  ];

  const regionTypes = REGION_TYPES.map(p => ({
    label: p,
    value: p,
  }));

  return (
    <Space size="middle" style={{ display: 'flex', alignItems: 'center' }}>
      {/* Sentiment Filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <h5 className="filters-select-label" style={{ margin: 0 }}>Sentiment:</h5>
        <Select
          style={{ minWidth: '120px', width: 'auto' }} // Dynamic width
          variant="outlined"
          mode="single"
          value={selectedSentiment}
          onChange={updateSelectedSentiment}
          options={sentimentTypes}
        />
      </div>

      {/* Region Filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <h5 className="filters-select-label" style={{ margin: 0 }}>Region:</h5>
        <Select
          style={{ minWidth: '120px', width: 'auto' }} // Dynamic width
          variant="outlined"
          mode="multiple"
          value={selectedRegion}
          onChange={updateSelectedRegion}
          options={regionTypes}
        />
      </div>
    </Space>
  );
};

export default RegionSentimentFilters;