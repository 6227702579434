import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Col, Row, Space } from 'antd';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import { formatFilters } from '../../utils/format-filters.js';

const LineChart = (props) => {
  const { id, data, xTicksFormatter, hasLegend, fileName, columns, filters, title } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [legend, setLegend] = useState([]);
  const [width, setWidth] = useState(0);

  const colors = ['#2E90FA'];
  const margin = { top: 16, right: 30, bottom: 28, left: 58 };
  const height = window.height < 200 ? 150 - margin.bottom - margin.top : 200 - margin.bottom - margin.top;
  const bisect = d3.bisector(d => d.date).left;

  function initChart() {
    const initialWidth = document.getElementById(`chart-${id}`).getBoundingClientRect().width - margin.left;
    const svg = d3.select(`#chart-${id}`)
      .append('svg')
      .attr('width', initialWidth + margin.left + margin.right)
      .attr('height', height + margin.bottom + margin.top)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);


    svg.append('g')
      .attr('class', 'grid-line');

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`);

    svg.append('g')
      .attr('class', 'y-axis');

    svg.append('g')
      .attr('class', 'chart-container');

    svg.append('g')
      .attr('class', 'focus')
      .append('circle')
      .attr('stroke', '#fff')
      .attr('r', 7)
      .attr('stroke-width', 8)
      .style('opacity', 0);

    setWidth(initialWidth);
  }

  function updateChart() {
    const svg = d3.select(`#chart-${id} svg g`);
    const focus = svg.select('.focus circle');
    const chartContainer = svg.select('.chart-container');

    svg.selectAll('.event-overlay').remove();

    if (hasLegend) {
      setLegend(Array.from(data).map((group, i) => ({
        color: colors[i],
        group: group[0]
      })));
    }

    const x = d3.scaleTime()
      .domain(d3.extent(data, d => new Date(d.date)))
      .range([0, width]);

    const yExtent = d3.extent(data, d => +d.value);
    const yPadding = (yExtent[1] - yExtent[0]) * 0.1;
    const y = d3.scaleLinear()
      .domain([Math.max(0, yExtent[0] - yPadding), yExtent[1] + yPadding])
      .range([height, 0])
      .nice();

    svg.selectAll('.x-axis')
      .transition()
      .duration(1000)
      .call(d3.axisBottom(x)
        .tickFormat(xTicksFormatter || (d => d))
        .ticks(Math.min(data.length, 10)));

    const yAxis = d3.axisLeft(y)
      .ticks(8)
      .tickFormat(d => d3.format('.2s')(d));

    svg.selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(yAxis);

    // Single bottom grid line
    const gridLine = svg.select('.grid-line');
    gridLine.selectAll('*').remove();
    gridLine.append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height)
      .attr('stroke', '#e5e7eb')
      .attr('stroke-dasharray', '4,4');

    svg.selectAll('.y-axis path, .x-axis path').remove();
    svg.selectAll('.tick line')
      .attr('stroke', '#e5e7eb');
    svg.selectAll('.tick text')
      .attr('class', 'chart_tick_text')
      .attr('fill', '#6b7280');

    const lineGenerator = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    const line = chartContainer.selectAll('.line')
      .data([data]);

    line.enter()
      .append('path')
      .merge(line)
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', colors[0])
      .attr('stroke-width', 2.5)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .transition()
      .duration(1000)
      .attr('d', lineGenerator);

    function mouseover() {
      setTooltipVisible(true);
      focus.style('opacity', 1);
    }

    function mousemove(event) {
      const mouseX = d3.pointer(event)[0];
      const x0 = x.invert(mouseX);
      const i = bisect(data, x0, 1);
      const selectedData = data[i];

      if (!selectedData) return;

      const tooltipXposition = x(selectedData.date);
      const tooltipYposition = y(selectedData.value);

      setTooltipTitle(selectedData.date.toLocaleDateString());
      setTooltipValue(d3.format(',.2f')(selectedData.value));

      focus
        .attr('cx', tooltipXposition)
        .attr('cy', tooltipYposition)
        .attr('fill', colors[0]);

      setTooltipTop(tooltipYposition + margin.top + 'px');
      setTooltipLeft(tooltipXposition + margin.left + 'px');
    }

    function mouseleave(event) {
      if (event.toElement &&
          typeof event.toElement.className === 'string' &&
          event.toElement.className.includes('chart-tooltip')) return;

      setTooltipVisible(false);
      focus.style('opacity', 0);
    }

    svg.append('rect')
      .attr('class', 'event-overlay')
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave);
  }

  useEffect(initChart, []);
  useEffect(updateChart, [data, width]);

  return (
    <div className="chart-wrapper">
      <div className="chart-container" id={`chart-${id}`}>
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className="download-btns">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="line-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism'
          }}
        />
      </div>
    </div>
  );
};

LineChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    value: PropTypes.number
  })).isRequired,
  xTicksFormatter: PropTypes.func,
  hasLegend: PropTypes.bool,
  fileName: PropTypes.string,
  columns: PropTypes.array,
  filters: PropTypes.object,
  title: PropTypes.string
};

LineChart.defaultProps = {
  hasLegend: false,
  fileName: 'chart-data',
  columns: [],
  filters: {},
  title: 'Line Chart'
};

export default LineChart;
