import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import Context from '../../context/context';

import { ESTABLISHMENT_TYPES } from '../../constants.js';
import './establishment-type-toggle.css';

const EstablishmentTypeToggle = () => {
  const { selectedCategory, updateSelectedCategory, establishmentTypes } =
    useContext(Context);

  useEffect(() => { updateSelectedCategory(null)}, [])

  return (
    <div className="establishment-type-toggle">
      {establishmentTypes.map((type, index) => {
        const typeToUse = type === 'All Categories' ? null : type
        return <button
          key={index}
          onClick={() => {
            selectedCategory === type
              ? updateSelectedCategory(null)
              : updateSelectedCategory(typeToUse);
          }}
          className={cx(
            'establishment-type-toggle-button button-main',
            {
              'button-main-inactive': selectedCategory === type || (type === "All Categories" && !selectedCategory)
            }
          )}
        >
          {type}
        </button>
      })}
    </div>
  );
};

EstablishmentTypeToggle.propTypes = {};

EstablishmentTypeToggle.defaultProps = {};

export default EstablishmentTypeToggle;
