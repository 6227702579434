import React from 'react';
import {
  InfoCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import './alert.css';

const variants = {
  default: {
    icon: <InfoCircleFilled className="alert-icon" />,
    className: 'alert-default',
  },
  error: {
    icon: <CloseCircleFilled className="alert-icon" />,
    className: 'alert-error',
  },
  warning: {
    icon: <ExclamationCircleFilled className="alert-icon" />,
    className: 'alert-warning',
  },
  success: {
    icon: <CheckCircleFilled className="alert-icon" />,
    className: 'alert-success',
  },
  info: {
    icon: <InfoCircleFilled className="alert-icon" />,
    className: 'alert-info',
  },
};

const Alert = ({ children, variant = 'default', className = '' }) => {
  const variantStyles = variants[variant] || variants.default;

  return (
    <div
      role="alert"
      className={`alert ${variantStyles.className} ${className}`}
    >
      <div className="alert-content">
        <div className="alert-icon-wrapper">{variantStyles.icon}</div>
        <div className="alert-body">{children}</div>
      </div>
    </div>
  );
};

export { Alert };
